.auth-login-sec {
    font-family: 'Poppins', sans-serif!important;
    padding: 6em 0;
}

.login-box h4{
    font-family: 'Poppins', sans-serif!important;
    font-size: 2em;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
}

.bg-illustration {
    position: relative;
    height: 88vh;
    background-repeat: no-repeat;
    background-position:100%;
    background-image: url('../Auth/why-not-login.png');
    background-size: cover;
    border-radius: 10px;
  }
  .bg-illustration img {
    width: 190px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: auto;
    margin: 19px 0 0 25px;
  }

  .login{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .login .login-box {
    margin: 0 auto;
    position: relative;
  }
  .login .login-box h1 {
    font-size: 35px;
    font-weight: bolder;
  }
  .login .login-box .login-form form {
    display: -ms-grid;
    display: grid;
  }
  .form-input {
    font-size: 16px;
    font-weight: normal;
    background: rgba(57, 57, 57, 0.07);
    margin: 10px 0;
    height: 60px;
    border: none;
    padding: 0 30px;
    border-radius: 10px;
  }
  .login .login-box .login-form .login-btn {
    background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
    background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
    background: linear-gradient(
        -20deg, #FFE61B 0%, #FFE61B 100%);
    border: none;
    color: var(--senary-color);
    display: inline-block;
    border-radius: 1000px;
    font-weight: 700;
    font-size: 15px;
    padding: 1em 2em;
    width: 40%;
    margin: auto;
  }

  .forget-pass{
      padding: 1em 0em 2em;
      text-align: right;
  }

  .login.register h6{
    margin: 12px 0 12px;
    font-size: 15px;
  }

  .login.register .forget-pass{
    text-align: center;
  }

  .login-footer{
    text-align: center;
  }

  .login-footer h6{
    margin: 15px 0 12px;
    font-size: 15px;
  }


